@import "styles/variables/antd.colors";

$primary-color: $blue-6;
$info-color: $primary-color;
$success-color: $green-6;
$processing-color: $blue-6;
$error-color: $red-5;
$highlight-color: $red-5;
$warning-color: $gold-6;
$normal-color: #d9d9d9;
$white: #fff;
$black: #000;

$primary-0: $blue-0;
$primary-1: $blue-1;
$primary-2: $blue-2;
$primary-3: $blue-3;
$primary-4: $blue-4;
$primary-5: $blue-5;
$primary-6: $primary-color;
$primary-7: $blue-7;
$primary-8: $blue-8;
$primary-9: $blue-9;

$body-background: #fff;
$background-color-light: hsl(0, 0, 97%); // background of header and selected item
$background-color-dark: rgba(0, 0, 0, 0.85); // background of header and selected item
$background-color-base: hsl(0, 0, 96%); // default grey background color

$component-background-1: hsl(0, 0, 100);
$component-background-2: hsl(0, 0, 96);
$component-background-3: hsl(0, 0, 92);
$component-background-4: hsl(0, 0, 88);
$component-background-5: hsl(0, 0, 84);
$component-background-6: hsl(0, 0, 80);

$component-background: $component-background-1;
$component-background-secondary: $component-background-2;
$component-background-dark: rgb(0, 21, 41);

$text-color: rgba($black, 0.85);
$text-color-secondary: rgba($black, 0.45);
$text-color-inverse: $white;
$icon-color: inherit;
$icon-color-hover: rgba($black, 0.75);
$heading-color: rgba($black, 0.85);
$text-color-dark: rgba($white, 0.85);
$text-color-secondary-dark: rgba($white, 0.65);
$text-selection-bg: $primary-color;
$font-variant-base: tabular-nums;
$font-feature-settings-base: "tnum";
$font-size-base: 14px;
$font-size-lg: $font-size-base + 2px;
$font-size-sm: 12px;
$heading-1-size: ceil($font-size-base * 2.71);
$heading-2-size: ceil($font-size-base * 2.14);
$heading-3-size: ceil($font-size-base * 1.71);
$heading-4-size: ceil($font-size-base * 1.42);
$heading-5-size: ceil($font-size-base * 1.14);
$line-height-base: 1.5715;

$border-radius-xss: 2px;
$border-radius-xs: 4px;
$border-radius-sm: 8px;
$border-radius-md: 16px;
$border-radius-lg: 32px;
$border-radius-base: $border-radius-sm;

// vertical paddings
$padding-lg: 24px; // containers
$padding-md: 16px; // small containers and buttons
$padding-sm: 12px; // Form controls and items
$padding-xs: 8px; // small items
$padding-xss: 4px; // more small
$padding-base: $padding-xs;

// vertical margins
$margin-lg: 24px; // containers
$margin-md: 16px; // small containers and buttons
$margin-sm: 12px; // Form controls and items
$margin-xs: 8px; // small items
$margin-xss: 4px; // more small
$margin-base: $padding-xs;

// height rules
$height-base: 32px;
$height-lg: 40px;
$height-sm: 24px;

// The background colors for active and hover states for things like
// list items or table cells.
$item-active-bg: $primary-1;
$item-hover-bg: #f5f5f5;

// LINK
$link-color: $primary-color;
$link-hover-color: $primary-5;
$link-active-color: $primary-7;
$link-decoration: none;
$link-hover-decoration: none;
$link-focus-decoration: none;
$link-focus-outline: 0;

// Animation
$ease-base-out: cubic-bezier(0.7, 0.3, 0.1, 1);
$ease-base-in: cubic-bezier(0.9, 0, 0.3, 0.7);
$ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-out-back: cubic-bezier(0.12, 0.4, 0.29, 1.46);
$ease-in-back: cubic-bezier(0.71, -0.46, 0.88, 0.6);
$ease-in-out-back: cubic-bezier(0.71, -0.46, 0.29, 1.46);
$ease-out-circ: cubic-bezier(0.08, 0.82, 0.17, 1);
$ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.34);
$ease-in-out-circ: cubic-bezier(0.78, 0.14, 0.15, 0.86);
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);

// Border color
$border-color-base: hsl(0, 0, 85%); // base border outline a component
$border-color-split: hsl(0, 0, 94%); // split border inside a component
$border-color-inverse: $white;
$border-width-base: 1px; // width of the border for a component
$border-style-base: solid; // style of a components border

// Outline
$outline-blur-size: 0;
$outline-width: 2px;
$outline-color: $primary-color;
$outline-fade: 20%;

// Disabled states
$disabled-color: rgba(#000, 0.25);
$disabled-bg: $background-color-base;
$disabled-color-dark: rgba(#fff, 0.35);

// Shadow
$shadow-color: rgba(0, 0, 0, 0.15);
$shadow-color-inverse: $component-background;
$shadow-1-up: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05),
  0 -12px 48px 16px rgba(0, 0, 0, 0.03);
$shadow-1-down: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05),
  0 12px 48px 16px rgba(0, 0, 0, 0.03);
$shadow-1-left: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05),
  -12px 0 48px 16px rgba(0, 0, 0, 0.03);
$shadow-1-right: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05),
  12px 0 48px 16px rgba(0, 0, 0, 0.03);
$shadow-2: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

// Media queries breakpoints
// Extra small screen / phone
$screen-xs: 480px;
$screen-xs-min: $screen-xs;

// Small screen / tablet
$screen-sm: 576px;
$screen-sm-min: $screen-sm;

// Medium screen / desktop
$screen-md: 768px;
$screen-md-min: $screen-md;

// Large screen / wide desktop
$screen-lg: 992px;
$screen-lg-min: $screen-lg;

// Extra large screen / full hd
$screen-xl: 1200px;
$screen-xl-min: $screen-xl;

// Extra extra large screen / large desktop
$screen-xxl: 1600px;
$screen-xxl-min: $screen-xxl;

// provide a maximum
$screen-xs-max: ($screen-sm-min - 1px);
$screen-sm-max: ($screen-md-min - 1px);
$screen-md-max: ($screen-lg-min - 1px);
$screen-lg-max: ($screen-xl-min - 1px);
$screen-xl-max: ($screen-xxl-min - 1px);

// Animation
$animation-duration-slow: 0.3s; // Modal
$animation-duration-base: 0.2s;
$animation-duration-fast: 0.1s; // Tooltip
