@import "styles/variables";

.Graphics {
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */

  &::-webkit-scrollbar {
    display: none !important;
  }

  position: relative;
  height: fit-content;
  overflow: scroll;
  margin: auto auto;

  background-color: #f4f5f8;

  overflow-x: hidden;

  &__header {
    display: flex;
    position: relative;
    margin-bottom: 8px;
    z-index: 2;
  }
  &__menu-item-content-wrapper {
    display: flex;
    align-items: center;
  }
  &__menu-item-content {
    margin-right: 10px;
  }
  &__content {
    position: relative;
    display: flex;
    z-index: 1;
    max-width: 100%;
    padding-top: 5em;
    height: fit-content;
    width: fit-content;
    margin: auto;
  }
  &__image-wrapper {
    display: flex;
    position: relative;
    margin-left: 2rem;

    &--dark {
      background-color: black;
    }
  }
}
.GraphicsImage {
  height: fit-content;
  .negative {
    fill: #4d566c;
    &--highlight {
      fill: #019875;
        &--no-knowledge-data {
          fill: #82ed91;
        }
        &--knowledge-data {
          fill: #019875;
        }
    }
    :hover {
      fill: #019875;
    }
  }
  .positive {
    fill: #4d566c;
    &--highlight {
      fill: #019875;
        &--no-knowledge-data {
          fill: #82ed91;
        }
        &--knowledge-data {
          fill: #019875;
        }
    }
    :hover {
      fill: #019875;
    }
  }
  .neutral {
    fill: #4d566c;
    &--highlight {
      fill: #019875;
        &--no-knowledge-data {
          fill: #82ed91;
        }
        &--knowledge-data {
          fill: #019875;
        }
    }
    :hover {
      fill: #019875;
    }
  }
  &--dark {
    background-color: black;
  }
}

.GraphicsThumbnail {
  overflow: hidden;

  .ImageMapper {
    svg {
      pointer-events: none;
      height: 5em;
    }
  }
}

.GraphicsList {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: end;
}

.GraphicsListElement {
  padding-right: 12px;
  &--bordered {
    margin-bottom: 6px;
    border-right: 6px solid black;
    border-radius: 12px;
    padding-right: 6px !important;
  }
}

.GraphicsElement {
  position: relative;
  display: flex;
  width: fit-content;
  height: fit-content;

  &--neutral {
    &--highlight {
      background-color: #fadb14;
    }
    :hover {
      background-color: #fadb14;
    }
  }

  &--positive {
    &--highlight {
      background-color: #25b34b;
    }
    :hover {
      background-color: #25b34b;
    }
  }

  &--negative {
    &--highlight {
      background-color: #f27072;
    }
    :hover {
      background-color: #f27072;
    }
  }

  &__container {
    align-self: center;
    align-items: center;
  }
  &__subject {
    padding: 4px;
    align-self: center;
  }
  &__condition {
    display: flex;
    padding: 2px;
    border: 1px dashed #a0a0a0;
    border-radius: 4px;
    align-self: center;
    &--borderless {
      border: none;
    }
  }
  &__label {
    padding: 3px;
    align-self: center;
  }
  &__record {
    padding: 2px;
    align-self: center;
  }
}

.GraphicsTag {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  text-align: center;
  &__tag {
    font-size: large;
    align-self: center;
  }
  &__tag-icon {
    font-size: medium;
    color: $red-6;
    margin-right: 4px;
  }
  &__item {
    font-size: 14px;
    border-top: 0px;
  }
}

.GraphicsInfo {
  border-radius: 16px;
}

.IGraphicsLines {
  position: absolute;
  overflow: visible;
  width: 100%;
  height: 100%;
  pointer-events: none;
  stroke-width: 1px;
  top: 0;
  &__svg {
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100%;

    &__highlight {
      position: absolute;
      pointer-events: none;
      width: 100%;
      height: 100%;
      opacity: 1;
      stroke: #019875;
      fill: #019875;
    }

    &__background {
      position: absolute;
      pointer-events: none;
      width: 100%;
      height: 100%;
      opacity: 0.2;
    }
  }

  &__line {
    fill: none;
    stroke: #8f00d0;
    stroke-linecap: round;
  }

  &__circle {
    fill: none;
    stroke-width: 1px;
    stroke-dasharray: 4px;
    stroke: #8f00d0;
    stroke-linecap: round;
  }
}

.GraphicsCausationLines {
  position: absolute;
  z-index: 10;
  overflow: visible;
  pointer-events: none;

  &__svg {
    position: absolute;
    z-index: 10;
    pointer-events: none;
    overflow: visible !important;

    &__highlight {
      opacity: 1;
      stroke-width: 3px;
    }

    &__background {
      opacity: 0.2;
    }
  }

  &__line {
    fill: none;
    stroke-width: 2px;
    stroke: #8f00d0;
    stroke-linecap: round;
  }

  &__circle {
    fill: none;
    stroke-width: 1px;
    stroke-dasharray: 4px;
    stroke: #8f00d0;
    stroke-linecap: round;
  }
}

.transform-component-module_wrapper__1_Fgj {
  overflow: visible !important;
}

@media only screen and (max-width: 700px) {
  .IGraphics__content {
    margin: unset;
    .IGraphicsList {
      padding-left: unset;
    }
  }
}

@media only screen and (max-width: 700px) {
  .IGraphics {
    &__content {
      margin: auto;
    }
  }
}

@media only screen and (max-width: 500px) {
  .IGraphics {
    &__content {
      padding-top: 2em;
    }
  }
}

.MuiChip-root {
  width: 15em !important;
  min-height: 2rem;
  height: fit-content !important;
  .MuiChip-label {
    overflow-wrap: break-word;
    white-space: normal;
    text-overflow: clip;
  }
}



body::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
}

#print {
  background-color: #f4f5f8;
}
