// Antd based colors
// Taken from antd.colors

$blue-base: #1890ff;
$purple-base: #722ed1;
$cyan-base: #13c2c2;
$green-base: #52c41a;
$magenta-base: #eb2f96;
$pink-base: #eb2f96;
$red-base: #f5222d;
$orange-base: #fa8c16;
$yellow-base: #fadb14;
$volcano-base: #fa541c;
$geekblue-base: #2f54eb;
$lime-base: #a0d911;
$gold-base: #faad14;

$red-0: #fff1f0;
$red-1: #ffccc7;
$red-2: #ffa39e;
$red-3: #ff7875;
$red-4: #ff4d4f;
$red-5: #f5222d;
$red-6: #cf1322;
$red-7: #a8071a;
$red-8: #820014;
$red-9: #5c0011;

$volcano-0: #fff2e8;
$volcano-1: #ffd8bf;
$volcano-2: #ffbb96;
$volcano-3: #ff9c6e;
$volcano-4: #ff7a45;
$volcano-5: #fa541c;
$volcano-6: #d4380d;
$volcano-7: #ad2102;
$volcano-8: #871400;
$volcano-9: #610b00;

$orange-0: #fff7e6;
$orange-1: #ffe7ba;
$orange-2: #ffd591;
$orange-3: #ffc069;
$orange-4: #ffa940;
$orange-5: #fa8c16;
$orange-6: #d46b08;
$orange-7: #ad4e00;
$orange-8: #873800;
$orange-9: #612500;

$gold-0: #fffbe6;
$gold-1: #fff1b8;
$gold-2: #ffe58f;
$gold-3: #ffd666;
$gold-4: #ffc53d;
$gold-5: #faad14;
$gold-6: #d48806;
$gold-7: #ad6800;
$gold-8: #874d00;
$gold-9: #613400;

$yellow-0: #feffe6;
$yellow-1: #ffffb8;
$yellow-2: #fffb8f;
$yellow-3: #fff566;
$yellow-4: #ffec3d;
$yellow-5: #fadb14;
$yellow-6: #d4b106;
$yellow-7: #ad8b00;
$yellow-8: #876800;
$yellow-9: #614700;

$lime-0: #fcffe6;
$lime-1: #f4ffb8;
$lime-2: #eaff8f;
$lime-3: #d3f261;
$lime-4: #bae637;
$lime-5: #a0d911;
$lime-6: #7cb305;
$lime-7: #5b8c00;
$lime-8: #3f6600;
$lime-9: #254000;

$green-0: #f6ffed;
$green-1: #d9f7be;
$green-2: #b7eb8f;
$green-3: #95de64;
$green-4: #73d13d;
$green-5: #52c41a;
$green-6: #389e0d;
$green-7: #237804;
$green-8: #135200;
$green-9: #092b00;

$cyan-0: #e6fffb;
$cyan-1: #b5f5ec;
$cyan-2: #87e8de;
$cyan-3: #5cdbd3;
$cyan-4: #36cfc9;
$cyan-5: #13c2c2;
$cyan-6: #08979c;
$cyan-7: #006d75;
$cyan-8: #00474f;
$cyan-9: #002329;

$blue-0: #e6f7ff;
$blue-1: #bae7ff;
$blue-2: #91d5ff;
$blue-3: #69c0ff;
$blue-4: #40a9ff;
$blue-5: #1890ff;
$blue-6: #096dd9;
$blue-7: #0050b3;
$blue-8: #003a8c;
$blue-9: #002766;

$geekblue-0: #f0f5ff;
$geekblue-1: #d6e4ff;
$geekblue-2: #adc6ff;
$geekblue-3: #85a5ff;
$geekblue-4: #597ef7;
$geekblue-5: #2f54eb;
$geekblue-6: #1d39c4;
$geekblue-7: #10239e;
$geekblue-8: #061178;
$geekblue-9: #030852;

$purple-0: #f9f0ff;
$purple-1: #efdbff;
$purple-2: #d3adf7;
$purple-3: #b37feb;
$purple-4: #9254de;
$purple-5: #722ed1;
$purple-6: #531dab;
$purple-7: #391085;
$purple-8: #22075e;
$purple-9: #120338;

$magenta-0: #fff0f6;
$magenta-1: #ffd6e7;
$magenta-2: #ffadd2;
$magenta-3: #ff85c0;
$magenta-4: #f759ab;
$magenta-5: #eb2f96;
$magenta-6: #c41d7f;
$magenta-7: #9e1068;
$magenta-8: #780650;
$magenta-9: #520339;

$grey-0: #a6a6a6;
$grey-1: #999999;
$grey-2: #8c8c8c;
$grey-3: #808080;
$grey-4: #737373;
$grey-5: #666666;
$grey-6: #404040;
$grey-7: #1a1a1a;
$grey-8: #000000;
$grey-9: #000000;

$preset-colors: pink, magenta, red, volcano, orange, yellow, gold, cyan, lime, green, blue, geekblue, purple;
