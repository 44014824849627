.IImageMapper {
  position: relative;
  height: 100%;
  width: auto;
  img {
    height: 100%;
    width: auto;
  }
  svg {
    .positive,
    .negative,
    .neutral {
      &--highlight{
        &-nofill {
          stroke: black;
          stroke-width: .1em;
          fill: none;
        }
      }

      &-nofill {
        stroke: black;
        stroke-width: .08em;
        fill: none;
      }
    }

    a {
      pointer-events: none;
    }
  }
  canvas {
    position: absolute;
    pointer-events: none;
    top: 0px;
    height: 100%;
    width: auto;
    overflow: hidden;
  }
}

.Isvges {
  position: relative !important;
  width: 9.6em !important;
  // stroke-width: 3 !important;
  fill: rgb(211, 211, 211) !important;
  border: none;
}

.ant-modal-mask {
  background-color: #000000
}



body::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
}
