@import "styles/variables";
@import "styles/variables/antd.variables";

html {
  // overflow-y: scroll;
}
body {
  background-color: $body-background;
}
.react-transform-component {
  width: 100% !important;
}
.App {
  background-color: #f4f5f8;
  &__layout {
    min-height: 100vh;
    overflow: hidden;
  }
  &__header {
    display: flex;
    flex-direction: row;
    padding: 0px 25px 0px 25px;
  }
  &__header-menu-wrapper {
    &--left {
      width: 100%;
      min-width: 64px;
    }
    &--right {
      float: right;
    }
  }
  &__header-popover {
    background-color: $background-color-dark;
  }
  &__content {
    position: relative;
    // background-color: $background-color-light;
    // background-image: url('/assets/background.png');
    // background-repeat: no-repeat;
    // background-size: cover;
  }

  &__footer {
    background-color: $background-color-dark;
    color: $text-color-dark;
    text-align: center;
    padding: $padding-sm;
  }
}

.page {
  margin: $padding-xss;
  height: 100%;
}
.page-card {
  // padding: $padding-base;
  height: 100%;
  border-radius: $border-radius-base;
  background-color: #f4f5f8;
}

@media print {
  .App {
    &__header {
      display: none;
    }
  }
}

.AnalysisPage {
  color: $text-color;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  height: 60rem;

  &__graphics {
    flex: 1 1 0;
    min-width: 900px;
    overflow-y: auto;
    position: relative;
    margin: auto
  }
}

#root {
  height: 100%;
  overflow: hidden;
}


@media only screen and (max-width: 700px) {
  .AnalysisPage {  
    &__graphics {
      max-width: 100%;
      min-width: 100%;
    }
  }
}

#wiki__btn {
  width: 90%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: 3rem;
  background-color: #019875;
  border: none;
}

body::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
}